import { getCookie } from './cookie';
let user = getCookie('identifier');
const getUserName = () => {
  const { firstName, middleName, lastName } = user?.user?.personName ?? {};
  return `${firstName} ${middleName} ${lastName}`;
};
const getIsNewQuote = () => {
  const { isNewQuote } = user?.user ?? {};
  return isNewQuote;
};
const getUserLoginToken = () => {
  const { token } = user?.user ?? {};
  return token;
};

const getLoginName = () => {
  const { credentials = {} } = user?.user?.accountInfo ?? {};
  return credentials?.loginName;
};

const getCode = () => {
  const { code, agencyOffice = {} } = user?.user?.producer ?? {};
  return { producerCode: code, agencyOfficeCode: agencyOffice?.code };
};
const getAgencyInfo = () => {
  const data = user?.user?.producer ?? {};
  return data;
};
const getAgencyCode = () => {
  return user?.user?.producer?.agency?.code;
};

const getRoles = () => {
  return user?.user?.roles;
};

const isThirdPartyReportAccess = () => {
  if (user?.user?.canViewThirdPartyReport === '1' || user?.user?.canViewThirdPartyReport === 1) {
    return true;
  } else {
    return false;
  }
};

const isInflectionReportAccess = () => {
  if (user?.user?.canViewInflectionReport === '1' || user?.user?.canViewInflectionReport === 1) return true;
  else return false;
};

const isAdminRole = () => {
  var userRoles = getRoles();
  return userRoles?.some((item) => item.name === 'Admin');
};

const isOwnerOfQuote = (proCode, agCode, agOffCode) => {
  var code = getCode();
  var producerCode = code?.producerCode;
  var agencyCode = getAgencyCode();
  var agencyOfficeCode = code?.agencyOfficeCode;
  if (!proCode || !producerCode) {
    return false;
  }
  return proCode == producerCode && agOffCode == agencyOfficeCode;
};

const getCommunications = () => {
  return user?.user?.communications;
};

const getUserInformation = () => {
  return user;
};

export {
  getUserName,
  getRoles,
  isAdminRole,
  isOwnerOfQuote,
  getCommunications,
  getUserInformation,
  getLoginName,
  getCode,
  getAgencyInfo,
  getUserLoginToken,
  isThirdPartyReportAccess,
  isInflectionReportAccess,
  getIsNewQuote,
};

import PropTypes from 'prop-types';
import React from 'react';

import './Spinner.css';

function Spinner(props) {
  return (
    <div className="overlay">
      <div className="spinner-over"></div>
    </div>
  );
}

Spinner.propTypes = {};

/* Exports ================================================================== */

export default Spinner;

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; 
import './App.css';
import React, { Component, Suspense, lazy, useEffect } from 'react';

import Spinner from './components/Spinner/Spinner';
import { getUserInformation } from './utils/User';
const Authentication = lazy(() => import('./authentication'));
const AZQuotesPage = lazy(() => import('./views'));
const PageNotFound = lazy(() => import('./page-not-found/PageNotFound'));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
    };
  }

  componentDidMount() {
    let data = getUserInformation();
    this.setState(data);
    const script = document.createElement('script');
    script.src = process.env.REACT_APP_PORTAL_ONE;
    script.async = true;
    document.head.appendChild(script);
    // Store the script reference in the class instance for cleanup
    this.script = script;
  }
  componentWillUnmount() {
    // Cleanup: Remove the script from the head when the component unmounts
    document.head.removeChild(this.script);
  }

  renderSpinner() {
    return <Spinner />;
  }

  renderAuthentication() {
    return <Authentication />;
  }

  renderAZQuotesPage() {
    return <AZQuotesPage />;
  }

  renderPageNotFound() {
    return <PageNotFound />;
  }

  renderApp() {
    const { user } = this.state;

    if (!user.accountInfo) {
      return this.renderAuthentication();
    }
    let domain = 0;
    switch (domain) {
      case 0:
        return this.renderAZQuotesPage();
      default:
        return this.renderPageNotFound();
    }
  }


  render() {
    return (
      <div>
        <Suspense fallback={this.renderSpinner()}>{this.renderApp()}</Suspense>
      </div>
    );
  }
}

export default App;
